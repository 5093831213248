/* Style for navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: #fff; /* White arrow color */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

/* Hover effect */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Light background */
  color:#ff7800; /* Black arrow color */
}

/* Positioning */
.swiper-button-prev {
  left: 10px; /* Adjust left positioning */
}

.swiper-button-next {
  right: 10px; /* Adjust right positioning */
}

/* Hide default Swiper arrow icons */
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px; /* Adjust arrow size */
  font-weight: bold;
}

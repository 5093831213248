/* Container for the button and popup */
.whatsapp-container {
  position: fixed;
  bottom: 3px;
  left: 20px;
  z-index: 1000; /* Ensures the button is on top of other elements */
}
  /* Button styling */
  .whatsapp-button {
    position: relative;
    background-color: #25d366;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    cursor: pointer;
    /* outline: none; */
    z-index: 5;
    float: left;
  }
  
  .whatsapp-button img {
    width: 30px;
    height: 30px;
    z-index: 5;
  }
  
  /* Glow effect (outer ripple animation) */
  .glow-effect {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(37, 211, 102, 0.5);
    animation: ripple 1.5s infinite;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
  
  /* Keyframe for the ripple effect */
  @keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.8;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.8);
      opacity: 0;
    }
  }
  

  

  

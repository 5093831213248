/* Main container styles */
.qa-facilities-page {
    background-color: #f8fafc;
    min-height: 100vh;
    padding: 4rem 1rem;
}

/* Section styles */
.section-container {
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    overflow: hidden;
}

/* Header styles */
.section-header {
    background: linear-gradient(to right, #2c3e50, #facc15);
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}

.section-title {
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

/* Content layout */
.section-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Table styles */
.qa-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    background: #ffffff;
}

.qa-table th {
    background: #2c3e50;
    color: #ffffff;
    font-weight: 800;
    text-align: left;
    padding: 0.75rem 1rem;
    
}

.qa-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    font-size: 15px;
}

.qa-table tr:hover {
    background-color: #f8fafc;
}

/* Raw material table specific styles */
.stock-table {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    overflow: hidden;
}

.stock-table-header {
    background: #f3f4f6;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    text-align: center;
}

.stock-table-title {
    font-weight: 900;
    color: #374151;
    margin: 0;
    text-align: center;
}

/* Image grid styles */
.image-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}
.image-grid1 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}

.qa-image {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.qa-image:hover {
    transform: scale(1.02);
}

/* Status indicators */
.status-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
}

.status-low {
    background-color: #2c3e50;
}

.status-medium {
    background-color: #2c3e50;
}

.status-high {
    background-color: #10b981;
}

/* Responsive design */
@media (min-width: 768px) {
    .qa-facilities-page {
        padding: 2rem;
    }

    .section-content {
        flex-direction: row;
    }

    .table-container {
        width: 50%;
    }

    .image-grid-container {
        width: 50%;
    }
}

@media (min-width: 1024px) {
    .qa-facilities-page {
        padding: 2rem 4rem;
    }

    .qa-image {
        max-height: 200px;
        object-fit: cover;
    }
}

/* Animation for hover effects */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Utility classes */
.text-right {
    text-align: right;
}

.font-medium {
    font-weight: 500;
}

.text-sm {
    font-size: 0.875rem;
}

.text-gray-600 {
    color: #4b5563;
}

.bg-gray-50 {
    background-color: #f9fafb;
}
.facilities-page {
    padding: 40px 0;
    background-color: #f8fafc;
}

.facilities-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.main-title {
    font-size: 4.5rem;
    font-weight: bold;
    text-align: center;
    color: #ff7800;
    margin-bottom: 40px;
}

.facility-section {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 40px;
}

.section-title {
    font-size: 2.8rem;
    font-weight: bold;
    color: #ff7800;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 2px solid #e5e7eb;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.table-container {
    flex: 1;
}

.category-title {
    font-size: 1.9rem;  /* Increased from 1.2rem */
    font-weight: 600;
    color: #3b4252;  /* Darker, more professional color */
    margin: 24px 0 16px;  /* Increased margins */
}

.facility-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 1.5rem;  /* Increased font size */
}

.facility-table th,
.facility-table td {
    padding: 14px;  /* Increased padding */
    text-align: left;
    border: 1px solid #d1d5db;  /* Lighter border color */
}

.facility-table th {
    background-color: #2c3e50;  /* Darker, more professional header */
    font-weight: 600;
    color: #ffffff;  /* White text for contrast */
}

.facility-table tr:hover {
    background-color: #f1f5f9;  /* Lighter hover effect */
}

.images-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.images-container1 {
    display: grid;
    
    gap: 20px;
}

.images-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;  /* Increased gap */
}

.facility-image {
    width: 100%;
    height: auto;
    border-radius: 8px;  /* Increased border radius */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Enhanced shadow */
    transition: transform 0.3s ease;  /* Smooth hover effect */
}

.facility-image:hover {
    transform: scale(1.02);  /* Slight zoom on hover */
}

/* Responsive Design */
@media (min-width: 768px) {
    .content-wrapper {
        flex-direction: row;
    }
    
    .images-container {
        grid-template-columns: repeat(2, 1fr);
        width: 40%;
    }
    .images-container1 {
        /* grid-template-columns: repeat(1, 1fr); */
        width: 40%;
    }
    
    /* Tool Room specific styles */
    .facility-section:nth-child(2) .table-container {
        width: 45%;  /* Decreased from 60% */
    }
    
    .facility-section:nth-child(2) .images-grid {
        width: 55%;  /* Increased from 40% */
    }
    
    .images-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .images-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    /* Tool Room specific styles */
    .facility-section:nth-child(2) .content-wrapper {
        gap: 40px;  /* Increased gap between table and images */
    }
}
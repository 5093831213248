/* ProcurementPartners.css */
.procurement-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
   
  }
  
  .procurement-header {
    background-color: #f8fafc; /* orange-500 */
    padding: 1.5rem;
  }
  
  .procurement-header h2 {
    color: black;
    font-size: 1.9rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
  
  .procurement-content {
    padding: 1.5rem;
  }
  
  .partner-group {
    margin-bottom: 2rem;
  }
  
  .partner-group:last-child {
    margin-bottom: 0;
  }
  
  .company-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }
  
  .company-card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
  }
  
  .company-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    /* height: 4rem; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .company-logo {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  
  /* Media Queries for Responsive Design */
  @media (min-width: 768px) {
    .company-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .procurement-header h2 {
      font-size: 2.75rem;

    }
  }
  
  @media (min-width: 1024px) {
    .company-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    
    .procurement-header h2 {
      font-size: 3rem;
    }
  }
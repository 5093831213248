.copy-right {
    text-align: center;  /* Centers the text horizontally */
    padding: 20px;
    margin-top: 20px;  /* Adjust padding to your preference */
  }
  
  .copyright-text {
    margin: 0;
    color: white;
    font-size: 15px;
  }
  .footer-logo{
    height: 20vh;
    margin-left: 50px;
  }
  
  .copy-right a {
    color: white;
    text-decoration: none; /* Removes underline from the link */
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .copy-right {
      padding: 10px; /* Adjust padding for smaller screens */
    }
  
    .copyright-text a {
      margin-left: 0; /* Remove left margin on mobile for better alignment */
    }
  }
  
  /* Desktop View */
  @media (min-width: 769px) {
    .copy-right {
      text-align: center;
      padding: 20px; /* Adjust padding for desktop */
    }
    .footer-list-items{
       margin-top:-30px;
    }
  }
  